import { Link } from "react-router-dom"

export const Sidebar = () => {

    return (
        <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

     
            <a className="sidebar-brand d-flex align-items-center justify-content-center" href="index.html">
                <div className="sidebar-brand-icon rotate-n-15">
                    <i className="fas fa-laugh-wink"></i>
                </div>
                <div className="sidebar-brand-text mx-3">Syscom<sup> 0.3</sup></div>
            </a>

           
            <hr className="sidebar-divider" />

         
            <div className="sidebar-heading">
                Interface
            </div>

            
            <li className="nav-item">
                <Link className="nav-link collapsed" to='/' data-toggle="collapse" data-target="#collapseTwo"
                    aria-expanded="true" aria-controls="collapseTwo">
                    <i className="fas fa-fw fa-home"></i>
                    <span>Home</span>
                </Link>
            </li>

            
            <li className="nav-item">
                <Link className="nav-link collapsed" to="/product" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-boxes"></i>
                    <span> Produtos</span>
                </Link>
            </li>

            <li className="nav-item">
                <Link className="nav-link collapsed" to="/user" data-toggle="collapse" data-target="#collapseUtilities"
                    aria-expanded="true" aria-controls="collapseUtilities">
                    <i className="fas fa-fw fa-users"></i>
                    <span> Users</span>
                </Link>
            </li>

            <hr className="sidebar-divider" />

            

        </ul>
    )
}