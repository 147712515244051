import {Sidebar} from '../SideBar';

export const Container = props => {

    return (
        <div id='page-top'>
            <div id='wrapper'>
                <Sidebar />
                {props.children}
            </div>
        </div>
    )
}