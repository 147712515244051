import React from 'react';


const Pagination = (props) => {

    const current_page = props.current_page;
    const last_page = props.last_page;
    const leftStatus = current_page === 1 ? false : true;
    const rightStatus = last_page === current_page ? "disabled" : "";


    const handleValue = value => {

        if (value >= 1 && value <= last_page) {
            props.setPage(value)
        }

    }

    const Iten = (pr) => {

        let actived = current_page === pr.value ? "btn-primary" : "btn-light"

        return (
            <a className={"btn " + actived + " btn-icon-split btn-sm"} style={{ marginLeft: "5px" }} onClick={() => { handleValue(pr.value) }}>
                <span class="text">{pr.value}</span>
            </a>
        )
    }

    const RenderItens = () => {

        const itens = [];

        for (let i = 1; i <= last_page; i++) {

            itens.push(<Iten value={i} />)
        }
        return itens;
    }

    return (

        <>
            <a className={"btn btn-secondary btn-icon-split btn-sm"} onClick={() => { handleValue(current_page - 1) }}>
                <span class="text"> {"<"} </span>
            </a>

            <RenderItens />

            <a className={"btn btn-secondary btn-icon-split btn-sm"} style={{ marginLeft: "5px" }} onClick={() => { handleValue(current_page + 1) }}>
                <span class="text"> {">"} </span>
            </a>
        </>

    )
}

export default Pagination;