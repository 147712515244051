import { Container } from "../Container"
import { Navibar } from "../NaviBar"

export const Wripper = props => {

    return (

        <Container>
            <div id="content-wrapper" className="d-flex flex-column">
                <div id="content">
                    <Navibar />
                    <div class="container-fluid">
                        {props.children}
                    </div>
                    
                </div>
            </div>
        </Container>

    )
}