export const FormProduct = props => {

    return (
        <form className="form-signin" onSubmit={props.onSubmitProduct}>
            <div className="form-label-group">
                <input type="text" id="inputName" className="form-control" placeholder="Name" required autofocus name="name" onChange={props.onChangeProduct} value={props.formProduct.name} />
                <label for="inputName">Name</label>
            </div>

            <div className="form-label-group">
                <input type="text" id="inputSlug" className="form-control" placeholder="Slug" required name="slug" onChange={props.onChangeProduct} value={props.formProduct.slug} />
                <label for="inputSlug">Slug</label>
            </div>

            <div className="form-label-group form-floating">

                <textarea type="text" id="textDescription" placeholder="description" className="form-control" required name="description" onChange={props.onChangeProduct} value={props.formProduct.description} />
            </div>

            <div className="form-label-group">
                <input type="text" id="inputPrice" className="form-control" placeholder="price" required name="price" onChange={props.onChangeProduct} value={props.formProduct.price} />
                <label for="inputPrice">Price</label>
            </div>

            <div>
                <button className="btn btn-secondary mr-3" type="button" data-dismiss="modal">Cancel</button>
                <button className="btn btn-primary" type="submit">Enviar</button>
            </div>

        </form>
    )
}