import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

import apiLink from '../../Components/Api';
import { CardDash } from '../../Components/CardDash';
import { Wripper } from '../../Components/Wripper';
import ValidateToken from '../../Services/ValidateToken';


const Home = () => {


    const [countProduct, setCountProduct] = useState(0);
    const [countUser, setCountUser] = useState(0);
    const token = localStorage.getItem('token');
    const apiUrl = apiLink();

    useEffect( async () => {
        
        getCount("/api/products/count").then( res => setCountProduct(res));
        getCount("/api/users/count").then( res => setCountUser(res));
    
    }, []);

    const getCount = async (link) => {

        try {

            //setLoad(true);

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }


            const response = await axios.get(apiUrl + link,
                {
                    headers: headers
                });


            if (response.data)
                return response.data;

            //setLoad(false);

        } catch (error) {
            if (error.response) {
                console.log(error.response.status)
                ValidateToken(error.response.status)
            }
            //setLoad(false);
        }

    }

    return (
        <Wripper>
            <h1>Home</h1>
            <Link to='/Product' style={{ textDecoration: 'none' }}>
                <CardDash description={"Products"} count={countProduct} icon={"fa-boxes"} />
            </Link>

            <Link to='/User' style={{ textDecoration: 'none' }}>
                <CardDash description={"Users"} count={countUser} icon={"fa-users"} />
            </Link>
        </Wripper>

    );
};

export default Home;