import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Wripper } from '../../Components/Wripper';
import apiLink from '../../Components/Api';
import ValidateToken from '../../Services/ValidateToken';
import Pagination from '../../Components/Pagination';
import { Modal } from '../../Components/Modal';
import { FormProduct } from './formProduct';


const Product = () => {


    const apiUrl = apiLink();

    const initialValues = {
        filter: '',
    };

    const initialValuesProduct = {
        name: '',
        slug: '',
        description: '',
        price: ''
    };

    const [load, setLoad] = useState(false);
    const [data, setData] = useState([]);
    const [current_page, setCurrent_page] = useState(null)
    const [last_page, setLast_page] = useState(null)
    const token = localStorage.getItem('token');
    const [form, setForm] = useState(initialValues);
    const [formProduct, setFormProduct] = useState(initialValuesProduct);


    const [bgModal, setBgModal] = useState('bg-gradient-primary');
    const [titleModal, setTitleModal] = useState('Title');
    const [bodyModal, setBodyModal] = useState('');


    const bgWarning = "bg-gradient-warning";
    const titleEdit = "Editar";
    const bgDanger = "bg-gradient-danger";
    const titleDelete = "Deletar";




    useEffect(() => {
        if (current_page == null)
            getDatas(1);
    }, [])


    const onChange = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        setForm(
            {
                ...form,
                [name]: value
            }
        );
    }

    const onChangeProduct = ev => {
        let name = ev.target.name;
        let value = ev.target.value;

        setFormProduct(
            {
                ...formProduct,
                [name]: value
            }
        );
    }


    const getDatas = async (page) => {

        try {

            setLoad(true);

            const headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }

            const filter = form !== '' ? `&name=${form.filter}` : ''

            const response = await axios.get(apiUrl + "/api/products?page=" + page + filter,
                {
                    headers: headers
                });

            if (response.data.data) {
                setData(response.data.data);
                setLast_page(response.data.last_page);
                setCurrent_page(response.data.current_page);
            }

            setLoad(false);

        } catch (error) {
            if (error.response) {
                console.log(error.response.status)
                ValidateToken(error.response.status)
            }
            setLoad(false);
        }

    }

    const onSubmit = ev => {
        ev.preventDefault();
        getDatas(1);
    }

    const onSubmitProduct = ev => {
        ev.preventDefault();
        console.log(formProduct);
    }


    const editItem = element => {
        setBodyModal(
            <FormProduct onSubmitProduct={onSubmitProduct} onChangeProduct={onChangeProduct} formProduct={formProduct} />
        )
        setFormProduct(element)
        setBgModal(bgWarning)
        setTitleModal(titleEdit)
    }

    const deletItem = element => {
        setFormProduct(element)
        setBgModal(bgDanger)
        setTitleModal(titleDelete)
    }

    return (

        <Wripper>
            <h1 class="h3 mb-2 text-gray-800">Products</h1>
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="m-0 font-weight-bold text-primary">DataTables Example</h6>

                    <form
                        className="d-none d-sm-inline-block form-inline mr-auto ml-md-3 my-2 my-md-0 mw-100 navbar-search"
                        onSubmit={onSubmit}
                    >
                        <div className="input-group">
                            <input type="text" className="form-control bg-light border-0 small"
                                placeholder="Search for..."
                                aria-label="Search" aria-describedby="basic-addon2"
                                name="filter"
                                onChange={onChange}
                            />

                            <div className="input-group-append">
                                <button className="btn btn-primary" type="submit">
                                    <i className="fas fa-search fa-sm"></i>
                                </button>
                            </div>
                        </div>
                    </form>


                </div>
                <div class="card-body">
                    <div class="table-responsive">
                        <table class="table table-bordered" width="100%" cellspacing="0">
                            <thead>
                                <tr>
                                    <th>name</th>
                                    <th>slug</th>
                                    <th>description</th>
                                    <th>price</th>
                                    <th>Actions</th>

                                </tr>
                            </thead>

                            <tbody>
                                {
                                    data.map((element, index) => {

                                        return (
                                            <tr>
                                                <td>{element.name}</td>
                                                <td>{element.slug}</td>
                                                <td>{element.description}</td>
                                                <td>{element.price}</td>
                                                <td>
                                                    <i className="fas fa-fw fa-edit text-warning" onClick={() => editItem(element)} data-toggle="modal" data-target="#logoutModal"></i>
                                                    <i className="fas fa-fw fa-trash text-danger" onClick={() => deletItem(element)} data-toggle="modal" data-target="#logoutModal"></i>
                                                </td>
                                            </tr>
                                        )

                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <Pagination setPage={getDatas} current_page={current_page} last_page={last_page} />
                </div>

                <Modal title={titleModal} background={bgModal}>

                    {bodyModal}

                </Modal>

            </div>
        </Wripper>



    );
};

export default Product;